<template>
    <div class="be-detail-page league">
      <hero-banner id="home" mode="fifty" :content="heroContent"/>
      <div class="container">
        <p>
            Alright, let us tell you about <b>Backfield Elite League One</b>—the kind of league where legends are made and egos are broken. We’re talkin’ 12 teams, 5 players per squad, going head-to-head every week. It’s like the Premier League... but smaller, scrappier, and way more fun to watch.</p>
            <br/>

<p>And here’s the kicker—there’s <b>prize on the line</b> for the winners. That’s right, you could walk in with your boots and walk out with bragging rights. It’s football, but with stakes so high, even your cat would show up to watch.</p>
<br/>
<p>But this ain’t just about the prize (okay, maybe a little). It’s about building something bigger—a community. You’ll meet players who’ll push you, teammates who’ll inspire you, and that one guy who never passes but somehow still scores.</p>
<br/>
<p>So, if you’re ready to leave it all on the pitch, sign up for Backfield Elite League One. Bring your talent, your passion, and maybe an ice pack—because it’s about to get real out here.</p>
<br/>
<p>We’d love to have you in our group. <br/><b> <a href="https://www.meetup.com/cambridge-backfield-elite-league-1/?eventOrigin=home_groups_you_organize" target="_blank">
            Join the fun
        </a></b>

        or

        <b><router-link to="#signup" @click="scrollToHash('signup')">sign up for the league</router-link></b>

        </p>

      </div>
      <sign-up is-league/>
    </div>
  </template>

<script>
import HeroBanner from '@/components/HeroBanner.vue'
import SignUp from '@/components/SignUp.vue'

export default {
  name: 'PolicyView',

  components: { HeroBanner, SignUp },

  data () {
    return {
      heroContent: {
        title: ''
      }
    }
  },

  methods: {
    scrollToHash (hash) {
      setTimeout(() => {
        document.getElementById(hash).scrollIntoView()
      }, 200)
    }
  }
}
</script>

<style lang="scss">
@import '../assets/styles/detailpage.scss';
</style>
